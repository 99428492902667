.heading {
    display: flex;
    justify-content: center;
    align-items: center;
}

.heading-curve {
    --mask:
        radial-gradient(57.88px at 50% calc(100% - 79.50px), #000 99%, #0000 101%) calc(50% - 60px) 0/120px 100%,
        radial-gradient(57.88px at 50% calc(100% + 49.50px), #0000 99%, #000 101%) 50% calc(100% - 30px)/120px 100% repeat-x;

    -webkit-mask: var(--mask, --mask2);
    mask: var(--mask);
    mask: var(--mask);
    width: 99vw;
    min-height: 35svh;
    background: rgb(28, 225, 192);
    background: linear-gradient(232deg, rgba(28, 225, 192, 1) 0%, rgba(64, 85, 229, 1) 100%);
    display: flex;
    text-align: center;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    font-size: 1.5em;
    padding-bottom: 1.5em;
}

.title {
    font-size: 1.5em;
}

.subtitle {
    text-align: center;
    font-size: large;
    margin: 5px;
    color: rgb(237, 237, 237);
}

.subtitle-container {
    display: flex;
    flex-wrap: wrap;
    background-color: red;
}

#auto-text {
    min-width: 100%;
    min-height: 1.5em;
    font-weight: bolder;
}

#blink {
    color: lightgray;
    border: 1px solid rgba(43, 43, 43, 0.573);
    margin: 5px;
    max-height: 10px;
    animation: blink .6s infinite;
}

@keyframes blink {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.brief-curve {
    min-height: 20svh;
    display: flex;
    flex-direction: column;
    font-size: x-large;
    width: 100%;
    background: rgb(92, 28, 225);
    background: linear-gradient(145deg, rgba(92, 28, 225, 1) 0%, rgba(176, 64, 229, 1) 100%);
    --mask:
        radial-gradient(67.08px at 50% 90.00px, #000 99%, #0000 101%) calc(50% - 60px) 0/120px 51% repeat-x,
        radial-gradient(67.08px at 50% -60px, #0000 99%, #000 101%) 50% 30px/120px calc(51% - 30px) repeat-x,
        radial-gradient(67.08px at 50% calc(100% - 90.00px), #000 99%, #0000 101%) calc(50% - 60px) 100%/120px 51% repeat-x,
        radial-gradient(67.08px at 50% calc(100% + 60.00px), #0000 99%, #000 101%) 50% calc(100% - 30px)/120px calc(51% - 30px) repeat-x;
    -webkit-mask: var(--mask);
    mask: var(--mask);
    font-weight: bold;
    padding-bottom: 1.5em;

}

.rowHome {
    padding-top: 2em;
    padding-bottom: 3.5em;
    flex-wrap: wrap;
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    gap: 1rem;
}

.cardContainer {
    margin-top: 2em;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 1.5em;
}

.techTitle {
    font-size: 1.5em;
    margin-left: .5em;
    margin-top: 1.4em;
    margin-bottom: 1em;
}

li {
    margin: .7em;
}

ul {
    list-style-type: disc;
}

.techDetails {
    margin: .5em;
    font-size: .8em;
    word-wrap: break-all;
    padding-bottom: 1.3em;
}

.detailCard {
    background-color: rgba(255, 255, 255, 0.25);
    padding: 1.5em 1em 2em 1em;
    border-radius: 1.8em;
    box-shadow: 0 0 25px 3px rgba(0, 0, 0, 0.4);
}

.space {
    margin-top: 3em;
    padding: 1.5em;
    margin-bottom: 4em;
    font-weight: bold;
}

.big {
    font-weight: bolder;
    font-size: 1.5em;
    margin: 0 0 .5em 0;
}

.line {
    border: 1px solid transparent;
    margin-top: 1em;
    margin-bottom: 1em;
}

.half-container {
    word-wrap: break-word;
    height: 100%;
    display: flex;
    width: fit-content;
    flex-direction: column;
    padding: 1.6rem 0rem;
    border-radius: 2.5rem;
    margin: .5em;

}

.g1 {

    background-image: linear-gradient(135deg, #667eea 0%, #764ba2 100%);

}

.g2 {
    background-image: linear-gradient(to top, #505285 0%, #585e92 12%, #65689f 25%, #7474b0 37%, #7e7ebb 50%, #8389c7 62%, #9795d4 75%, #a2a1dc 87%, #b5aee4 100%);
}

.g3 {
    background-image: linear-gradient(to top, #1e3c72 0%, #1e3c72 1%, #2a5298 100%);
}

.card-title {
    height: 20%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.8rem;
    margin-top: 5px;
    font-weight: bold;
}

.tag {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
    margin: 15px 15px 15px 15px;
}


.data-bubble {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 7px;
    background-color: rgba(255, 255, 255, 0.097);
    backdrop-filter: blur(40px);
    border-radius: 50px;
    padding: 10px;
    height: max-content;
    width: max-content;
    flex-shrink: 0;
    letter-spacing: 1px;
    font-size: 0.9rem;
    font-weight: bold;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.155);
    transition: all 200ms ease-in-out;
    user-select: none;
}

.data-bubble:hover {
    scale: 0.8;
    background-color: rgba(144, 144, 144, 0.099);

}

.data-bubble:hover {
    cursor: pointer;
}

#link-projects {
    display: flex;
    align-items: center;
    color: aqua;
}

.upper {
    margin-bottom: 2em;
    height: max-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.upper2 {
    margin-bottom: 2em;
    height: max-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}


.welcomeText {
    margin: 1em;
    font-size: 1.2em;
}

.n {
    font-size: 1.4em;

}

.currentFocus {
    margin: 1em;
    font-size: 1.2em;
    font-weight: bold;

}

.my-brief {
    padding-left: 1.5rem;
}

.mybrief-list {
    font-size: medium;
    font-weight: bold;
}

.fa-location-dot {
    color: dodgerblue;
    font-size: larger;
}

.fa-sticky-note {
    color: gold;
}

.fa-wrench {
    color: lightgray;
}

.seperator {
    position: relative;
    display: flex;
    align-items: center;
    gap: 5px;
    width: 100%;
    padding-left: 0.4rem;
}

.border-line {
    border: 2px solid rgba(255, 255, 255, 0.135);
    width: max-content;
    height: 0;
    flex-grow: 0.95;
}

.tech-title {
    width: fit-content;
    font-size: large;
    font-weight: bold;
}

.logos {
    height: 37px;
    width: 37px;
    border-radius: 50%;
}