.projectpagetitle {
    --mask:
        radial-gradient(67.08px at 50% calc(100% - 90.00px), #000 99%, #0000 101%) calc(50% - 60px) 0/120px 100%,
        radial-gradient(67.08px at 50% calc(100% + 60.00px), #0000 99%, #000 101%) 50% calc(100% - 30px)/120px 100% repeat-x;
    -webkit-mask: var(--mask);
    mask: var(--mask);

    min-height: 30svh;

    background: rgb(28, 225, 192);
    background: linear-gradient(232deg, rgba(28, 225, 192, 1) 0%, rgba(64, 85, 229, 1) 100%);
    font-size: 2em;
    font-weight: bolder;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 1.5em;
    position: relative;
    overflow: visible;
}

.projectDesc {
    font-size: medium;
    color: rgb(237, 237, 237);
    text-align: center;
    padding: 0.8em;
}

#titleText {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding: .55em 0 0 0;
}

.projectContainer {
    background: rgb(92, 28, 225);
    background: linear-gradient(145deg, rgba(176, 64, 229, 1) 100%, rgba(92, 28, 225, 1) 0%, );
    width: 100%;
    --mask:
        radial-gradient(67.08px at 50% 90px, #000 99%, #0000 101%) calc(50% - 60px) 0/120px 100%,
        radial-gradient(67.08px at 50% -60px, #0000 99%, #000 101%) 50% 30px/120px 100% repeat-x;
    -webkit-mask: var(--mask);
    mask: var(--mask);
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
    gap: 2em;
    padding: 3.5em 1em 3em 1em;
    overflow-x: hidden;
    overflow-y: auto;
    min-height: 70svh;
    position: relative;
}

.failedContainer {
    background: rgb(92, 28, 225);
    background: linear-gradient(145deg, rgba(176, 64, 229, 1) 100%, rgba(92, 28, 225, 1) 0%, );
    width: 100%;
    --mask:
        radial-gradient(67.08px at 50% 90px, #000 99%, #0000 101%) calc(50% - 60px) 0/120px 100%,
        radial-gradient(67.08px at 50% -60px, #0000 99%, #000 101%) 50% 30px/120px 100% repeat-x;
    -webkit-mask: var(--mask);
    mask: var(--mask);
    min-height: 70svh;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: large;
    font-weight: bolder;
}

.loadingContainer {
    --mask:
        radial-gradient(67.08px at 50% 90px, #000 99%, #0000 101%) calc(50% - 60px) 0/120px 100%,
        radial-gradient(67.08px at 50% -60px, #0000 99%, #000 101%) 50% 30px/120px 100% repeat-x;
    -webkit-mask: var(--mask);
    mask: var(--mask);
    background: rgb(92, 28, 225);
    background: linear-gradient(145deg, rgba(176, 64, 229, 1) 100%, rgba(92, 28, 225, 1) 0%, );
    height: 70svh;
    width: 100%;

    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
    gap: 3em;
    padding: 5em 1em 1em 1em;
    overflow-x: hidden;
    overflow-y: hidden;

}

@media (width < 800px) {
    .projectContainer {
        padding-bottom: 4em;
    }
}


.errorContainer {
    position: absolute;
    background-color: rgba(0, 0, 0, 0.481);
    height: 100svh;
    width: 95svw;
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(5px);
}

.errorMessage {
    background-color: rgba(255, 255, 255, 0.151);
    padding: 2rem;
    border-radius: 20px;
}

.errorTitle {
    font-size: xx-large;
}

.errorDesc {
    font-size: larger;
}