/*            --------------------------- Desktop Version Of Navbar Below -------------------               */

@media (width > 1000px) {

    .mainContainer {
        height: 100vh;
        width: 5vw;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        overflow-y: auto;
        overflow-x: hidden;
        color: rgba(255, 255, 255, 0.641);
        transition: color 0.5s ease-in-out;
    }

    .iconContainer {
        margin-top: 3.5em;
        text-align: center;
        font-size: large;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        height: 45%;

    }

    .themesContainer {
        font-size: smaller;
        top: 0;
        font-size: large;
        transition: scale 0.3s ease-in-out;
    }

    .themesContainer:active {
        scale: 1.8;
    }

    .icons {
        cursor: pointer;
        padding: 10px;
        transition: scale 0.2s ease-out;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        overflow: hidden;
    }

    .icons>.icon-text {
        font-size: 0.4em;
        margin-top: 7px;
        letter-spacing: 3px;
    }

    .icons:active {
        scale: 0.8;
    }

    .selectedBorder {
        color: white;
        text-shadow: 0 0 10px #fff;
        transition: color 1s ease-in-out;
        transition: scale 0.2s ease-out;

    }

    .mylogo {
        padding-top: 1em;
        height: 50px;
        width: 50px;
    }

    .mylogo:active {
        animation: turn .5s 4 ease-in-out;
        transform-origin: 45px 45px;

    }

    @keyframes turn {
        0% {
            transform: translate(1px, 1px) rotate(0deg);
        }

        10% {
            transform: translate(-1px, -2px) rotate(-1deg);
        }

        20% {
            transform: translate(-3px, 0px) rotate(1deg);
        }

        30% {
            transform: translate(3px, 2px) rotate(0deg);
        }

        40% {
            transform: translate(1px, -1px) rotate(1deg);
        }

        50% {
            transform: translate(-1px, 2px) rotate(-1deg);
        }

        60% {
            transform: translate(-3px, 1px) rotate(0deg);
        }

        70% {
            transform: translate(3px, 1px) rotate(-1deg);
        }

        80% {
            transform: translate(-1px, -1px) rotate(1deg);
        }

        90% {
            transform: translate(1px, 2px) rotate(0deg);
        }

        100% {
            transform: translate(1px, -2px) rotate(-1deg);
        }
    }

}

/*            --------------------------- Mobile Version Of Navbar Below -------------------               */

@media (width < 1000px) {
    .mainContainer {
        user-select: none;
        z-index: 99;
        height: max-content;
        padding-top: 10px;
        padding-bottom: 10px;
        position: fixed;
        bottom: 0px;
        left: 0;
        right: 0;
        width: 100vw;
        overflow-y: auto;
        background-color: rgba(19, 17, 25, 0.4);
        overflow-x: hidden;
        backdrop-filter: blur(39px);
        border-radius: 3px;
        color: rgba(255, 255, 255, 0.641);
        transition: color 1s ease-in-out;
    }

    .themesContainer {
        font-size: large;
        transition: scale 0.3s ease-out;


    }

    .mylogo {
        display: none;
    }

    .iconContainer {
        width: 100%;
        height: max-content;
        display: flex;
        align-items: start;
        justify-content: space-evenly;
        font-size: 1.3rem;

    }

    .selectedBorder {
        transition: color 1s ease-in-out;

        color: white;
        text-shadow: 0 0 10px #fff;
    }

    .icons {
        cursor: pointer;
        padding: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: background-color 0.4s ease-out;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
    }

    .icons>.icon-text {
        font-size: xx-small;
        margin-top: 7px;
        letter-spacing: 2px;
    }

    .icons:active {
        scale: 0.8;

    }

    .themesContainer:active {
        scale: 1.8;
    }
}


/* -------------------------------------------------Common Version-------------------------------------------*/

.mainContainer {
    border-radius: 5px;
}

.notify-icon {
    position: relative;
    animation: shaking 1s infinite;
}

@keyframes shaking {

    0% {
        transform: translateX(0);
    }

    5%,
    15%,
    25%,
    35%,
    45% {
        transform: translateX(4px);
    }

    10%,
    20%,
    30%,
    40%,
    50% {
        transform: translateX(0);
    }
}

.notify-icon::after {
    content: "1";
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: xx-small;
    font-weight: bolder;
    position: absolute;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: red;
    border: 2px solid white;
    right: 1px;
    top: 1px;
    z-index: 100;

}