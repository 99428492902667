.main {
    height: 430px;
    background-color: rgba(255, 255, 255, 0.429);
    backdrop-filter: blur(10px);
    box-shadow: 0px 0px 22px 1px rgba(0, 0, 0, 0.336);
    border-radius: 20px;
    padding: .5em;
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
    align-items: flex-start;
}

.main:hover {
    background-color: rgba(228, 228, 228, 0.402);

}

.cardTitle {
    font-size: x-large;
    font-weight: bolder;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;

}

.cardBody {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    height: 50%;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    background-color: rgba(0, 0, 0, 0.05);
    padding: 1em;
    border-radius: 1.4em;
}


.cardButtonContainer {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
}

.cardButton {
    font-size: 1.1em;
    border-radius: 40px;
    cursor: pointer;
    user-select: none;
    padding: .68em 1em 0.68em 1em;
    width: max-content;
    font-weight: bold;
    border: none;
    outline: none;
}

.cardButton:hover {
    background: rgb(110, 45, 239);

}

.cardButton:active {
    background: rgb(94, 39, 206);
}

.cardButton:focus {
    outline: none;
    border: 5px solid rgb(255, 255, 255);
    border-radius: 40px;
}

#linkkk {
    outline: none;
    border: none;
}

#button:focus {
    outline: none;
    border: 5px solid rgb(255, 255, 255);
    border-radius: 40px;
}

.disable-btn {
    background: rgba(117, 70, 210, 0.341);
    font-size: 1.1em;
    border-radius: 40px;
    cursor: not-allowed;
    user-select: none;
    padding: .68em 1em 0.68em 1em;
    width: max-content;
    font-weight: bold;
    border: none;
    outline: none;
}

.disable-btn:hover::after {
    content: "Live View is not available for this project 🙁";
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 5%;
    backdrop-filter: blur(20px);
    padding: 15px 10px 15px 10px;
    z-index: 100;
    background: rgba(90, 28, 225, 0.531);
    border-radius: 2rem;
    -webkit-box-shadow: 1px -1px 59px -11px rgba(0, 0, 0, 0.426);
    -moz-box-shadow: 1px -1px 59px -11px rgba(0, 0, 0, 0.434);
    box-shadow: 1px -1px 59px -11px rgba(0, 0, 0, 0.6);
}

.code-btn {
    background: rgba(111, 58, 217, 0.802);

}

.live-btn {
    background: rgb(106, 45, 230);
    font-weight: bolder;
}