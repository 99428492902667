.mainLoading {
    height: 430px;
    background-color: rgba(255, 255, 255, 0.5);
    backdrop-filter: blur(10px);
    box-shadow: 0px 0px 22px 1px rgba(0, 0, 0, 0.336);
    border-radius: 20px;
    padding: .5em;
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
    align-items: flex-start;
    animation: load 5s ease-in-out infinite forwards;
}

.cardTitleLoading {
    font-size: x-large;
    font-weight: bolder;
    width: 100%;
    margin: .5em 0 0 0;
    overflow: hidden;
    text-overflow: ellipsis;
}

.cardBodyLoading {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    height: 50%;
    margin-top: 2em;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    background-color: rgba(0, 0, 0, 0.05);
    padding: 1em;
    border-radius: 1.4em;
}




@keyframes load {
    0% {
        background-color: rgba(255, 255, 255, 0.5);
    }

    40% {
        background-color: rgba(124, 124, 124, 0.5);

    }

    60% {
        background-color: rgba(255, 255, 255, 0.5);
    }

    80% {
        background-color: rgba(124, 124, 124, 0.5);


    }

    100% {
        background-color: rgba(255, 255, 255, 0.5);


    }

}