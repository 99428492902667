.parentContact {
    --mask:
        radial-gradient(67.08px at 50% 90.00px, #000 99%, #0000 101%) calc(50% - 60px) 0/120px 100%,
        radial-gradient(67.08px at 50% -60px, #0000 99%, #000 101%) 50% 30px/120px 100% repeat-x;
    -webkit-mask: var(--mask);
    mask: var(--mask);
    padding: 0;
    width: 100%;
    overflow: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: auto;
    background: rgb(92, 28, 225);
    background: linear-gradient(145deg, rgba(92, 28, 225, 1) 0%, rgba(176, 64, 229, 1) 100%);
}

.contactTitle {
    font-weight: bold;

    --mask:
        radial-gradient(67.08px at 50% calc(100% - 90.00px), #000 99%, #0000 101%) calc(50% - 60px) 0/120px 100%,
        radial-gradient(67.08px at 50% calc(100% + 60.00px), #0000 99%, #000 101%) 50% calc(100% - 30px)/120px 100% repeat-x;
    -webkit-mask: var(--mask);
    mask: var(--mask);

    background: rgb(28, 225, 192);
    background: linear-gradient(232deg, rgba(28, 225, 192, 1) 0%, rgba(64, 85, 229, 1) 100%);
    width: 100%;
    height: 30svh;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-size: 2em;
}

.subtitleContact {
    text-align: center;
    font-size: medium;
    color: rgb(237, 237, 237);
    margin: 10px;
    padding-bottom: 1.5em;
}

.confirmation {
    font-size: large;
    font-weight: bold;
    color: rgb(237, 237, 237);
}

form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    border-radius: 30px;
    width: 100%;
    gap: 3.5rem;
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
}

.formContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30vw;
    backdrop-filter: blur(20px);
    background-color: rgba(255, 255, 255, .35);
    border-radius: 30px;
    box-shadow: 0px 0px 30px 4px rgba(0, 0, 0, 0.5);
    z-index: 10;
    margin-top: 4em;
    gap: 2rem;


}

input {
    background-color: transparent;
    border-bottom: 2px solid white;
    padding: .5em;
    width: 70%;
    color: white;
    border-top-left-radius: 10px;
    font-size: large;
    border-top-right-radius: 10px;
    font-weight: bold;
}

textarea {
    background-color: transparent;
    border-bottom: 2px solid white;
    padding: .5em;
    width: 70%;
    color: white;
    border-top-left-radius: 10px;
    font-size: large;
    border-top-right-radius: 10px;
    font-weight: bold;
    height: 2.7em;
}

::selection {
    background-color: rgb(92, 28, 225);

}

textarea::placeholder {
    color: rgba(255, 255, 255, 0.539);

}

textarea:focus {
    outline: none;
    background-color: rgba(74, 71, 71, 0.181);
}

input::placeholder {
    color: rgba(255, 255, 255, 0.539);
}

input:focus {
    outline: none;
    background-color: rgba(74, 71, 71, 0.181);
}

#contactBtn {
    color: white;
    font-size: 1.7em;
    background-color: rgb(100, 22, 234);
    box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.292);
    padding: 0.2em 1em 0.2em 1em;
    border-radius: 50px;
    font-weight: bold;
    height: 2.5em;
    width: 40%;
    display: flex;
    justify-content: center;
    align-items: center;
}

#contactBtn:active {

    box-shadow: 0 0 0px 0px;
    background-color: rgb(88, 21, 203);

}

#contactBtn:disabled {
    background-color: rgb(46, 8, 112);

}

.contact-loading {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    border-left: 3px solid white;
    border-right: 3px solid white;
    border-top: 3px solid white;
    animation: rotate infinite 0.7s ease-in-out backwards;
}

@keyframes rotate {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

#contactBtn:focus {
    outline: none;
    border: 5px solid rgb(255, 255, 255);
    border-radius: 40px;
}


.socialLogos {
    display: flex;
    width: 100%;
    justify-content: space-evenly;
    align-items: center;
    font-size: 3em;
    margin-top: 1.5em;
}

.i {
    cursor: pointer;
    box-shadow: 0px 0px 30px 3px rgba(0, 0, 0, 0.493);
    border-radius: 50%;
}

.i:hover {
    color: lightgray;
}

.i:active {
    color: white;
}

@media (width < 800px) {
    .formContainer {
        width: 90vw;
        min-height: 70%;
    }

    .parentContact {
        padding-bottom: 5em;
        min-height: 700px;
    }
}